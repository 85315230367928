import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import DownArrow from '../../../../public/static/icons/navigation-chevron-down-desktop.svg';
import Button from '../../Button';

const LoadMoreButton = ({ onClick, label = 'See more', className = '' }) => (
  <div className={`${styles['load-more-button']} ${className}`}>
    <Button
      className={styles['button']}
      type="secondary"
      tag="button"
      size="large"
      onClick={onClick}
      tabIndex="0"
    >
      {label}
      <DownArrow alt="" />
    </Button>
  </div>
);

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default LoadMoreButton;

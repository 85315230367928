import React from 'react';
import PropTypes from 'prop-types';
import ClipboardIcon from '../../../../../public/static/icons/clipboard.svg';
import SearchBoldIcon from '../../../../../public/static/icons/search-bold.svg';
import FormattedText from '../../../FormattedText';
import Button from '../../../Button';

import styles from './index.module.scss';

const NoResultsBlock = ({
  text = '',
  onButtonClick,
  analyticsClassName = '',
  buttonLabel = 'Clear and start again',
}) => (
  <div className={`no-results-block ${styles['no-results-block']}`}>
    <div className="no-results-icon">
      <ClipboardIcon className="clipboard-icon" />
      <SearchBoldIcon className="search-icon" />
    </div>
    <FormattedText className="no-results-text" text={text} />
    <Button
      type="secondary"
      size="large"
      tabIndex="0"
      onClick={onButtonClick}
      className={analyticsClassName}
      data-test="cancel-search-testsuite"
    >
      {buttonLabel}
    </Button>
  </div>
);

NoResultsBlock.propTypes = {
  buttonLabel: PropTypes.string,
  text: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  // Replace analyticsClassName variable with data-analytics parameter.
  analyticsClassName: PropTypes.string,
};

export default NoResultsBlock;

import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../../../../public/static/icons/knowledge-hub-search.svg';
import Link, { propTypes as linkPropTypes } from '../../../Link';

import styles from './index.module.scss';

const NoResultsLinks = ({ title = '', links, analyticsClassName = '' }) => (
  <div className={`no-results-links ${styles['no-results-links']}`}>
    <div className="explore-links-block">
      {title && <div className="explore-intro">{title}</div>}
      {links.map((link) => (
        <div className="explore-link" key={link.label}>
          <SearchIcon className="search-icon" />
          <Link {...link.nextLink}>
            <a
              href={link.nextLink.url}
              className={analyticsClassName}
              data-analytics={analyticsClassName || null}
            >
              {link.label}
            </a>
          </Link>
        </div>
      ))}
    </div>
  </div>
);

NoResultsLinks.propTypes = {
  title: PropTypes.string,
  analyticsClassName: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape(linkPropTypes),
    }),
  ).isRequired,
};

export default NoResultsLinks;
